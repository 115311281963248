export const actionTypes = {

  // Base
  INIT_USER: 'INIT_USER',
  GET_ADMIN_DATA: 'GET_ADMIN_DATA',
  GET_CUSTOMER_DATA: 'GET_CUSTOMER_DATA',
  LOGOUT_CUSTOMER: 'LOGOUT_CUSTOMER',
  LOGOUT_ADMIN: 'LOGOUT_ADMIN',
  CHANGE_CUSTOMER_DATA: 'CHANGE_CUSTOMER_DATA',
  CHANGE_ADMIN_DATA: 'CHANGE_ADMIN_DATA',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SAVE_USER_DATA: 'SAVE_USER_DATA',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  DISCONNECT_INSTAGRAM_ACCOUNT: 'DISCONNECT_INSTAGRAM_ACCOUNT',
  GET_MARKET_INSTAGRAM_MEDIA: 'GET_MARKET_INSTAGRAM_MEDIA',

  // Anonymous
  LOGIN_USER: 'LOGIN_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REGISTER_USER: 'REGISTER_USER',
  GET_RESET_PASSWORD_DATA: 'GET_RESET_PASSWORD_DATA',
  GET_CONFIRMATION_DATA: 'GET_CONFIRMATION_DATA',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_RESET_PASSWORD: 'CONFIRM_RESET_PASSWORD',
  GET_ANON_MARKET: 'GET_ANON_MARKET',
  CREATE_USER: 'CREATE_USER',
  CREATE_MARKET: 'CREATE_MARKET',
  SEND_FEEDBACK: 'SEND_FEEDBACK',

  // Admin
  GET_ADMIN_MARKET: 'GET_ADMIN_MARKET',
  UPDATE_MARKET_SETTINGS: 'UPDATE_MARKET_SETTINGS',
  UPDATE_MARKET_LOGO: 'UPDATE_MARKET_LOGO',
  DELETE_MARKET_LOGO: 'DELETE_MARKET_LOGO',
  CREATE_MARKET_IMAGE: 'CREATE_MARKET_IMAGE',
  DELETE_MARKET_IMAGE: 'DELETE_MARKET_IMAGE',
  GET_CUSTOMER_LIST: 'GET_CUSTOMER_LIST',
  GET_ALL_CUSTOMERS: 'GET_ALL_CUSTOMERS',
  GET_CUSTOMER: 'GET_CUSTOMER',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  SEND_EMAIL_CONFIRMATION: 'SEND_EMAIL_CONFIRMATION',
  VERIFY_CUSTOMER: 'VERIFY_CUSTOMER',
  RESET_CUSTOMER_PASSWORD: 'RESET_CUSTOMER_PASSWORD',
  GET_PAYMENTS_INFO: 'GET_PAYMENTS_INFO',
  GET_SAVED_PERIODS: 'GET_SAVED_PERIODS',
  GET_SHELF_LIST: 'GET_SHELF_LIST',
  ADD_SHELF: 'ADD_SHELF',
  EDIT_SHELF: 'EDIT_SHELF',
  DELETE_SHELF: 'DELETE_SHELF',
  GET_CAMPAIGN_LIST: 'GET_CAMPAIGN_LIST',
  ADD_CAMPAIGN: 'ADD_CAMPAIGN',
  EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  GET_SHELF_BOOKING_LIST: 'GET_SHELF_BOOKING_LIST',
  ADD_SHELF_BOOKING: 'ADD_SHELF_BOOKING',
  EDIT_SHELF_BOOKING: 'EDIT_SHELF_BOOKING',
  DELETE_SHELF_BOOKING: 'DELETE_SHELF_BOOKING',
  GET_CUSTOMER_BOOKINGS: 'GET_CUSTOMER_BOOKINGS',
  GET_CUSTOMER_PRODUCT_SHEETS: 'GET_CUSTOMER_PRODUCT_SHEETS',
  GET_CUSTOMER_PRODUCT_SHEET: 'GET_CUSTOMER_PRODUCT_SHEET',
  CREATE_CUSTOMER_PRODUCT_SHEET: 'CREATE_CUSTOMER_PRODUCT_SHEET',
  UPDATE_CUSTOMER_PRODUCT_SHEET: 'UPDATE_CUSTOMER_PRODUCT_SHEET',
  COMPLETE_CUSTOMER_PRODUCT_SHEET: 'COMPLETE_CUSTOMER_PRODUCT_SHEET',
  DELETE_CUSTOMER_PRODUCT_SHEET: 'DELETE_CUSTOMER_PRODUCT_SHEET',
  GET_CUSTOMER_PRODUCT_SALE_LINE_LIST: 'GET_CUSTOMER_PRODUCT_SALE_LINE_LIST',
  GET_CUSTOMER_PRODUCT_LIST: 'GET_CUSTOMER_PRODUCT_LIST',
  CREATE_CUSTOMER_PRODUCTS: 'CREATE_CUSTOMER_PRODUCTS',
  PRINT_CUSTOMER_PRODUCT_SHEET: 'PRINT_CUSTOMER_PRODUCT_SHEET',
  ADD_PRODUCTS_TO_CAMPAIGN: 'ADD_PRODUCTS_TO_CAMPAIGN',
  REMOVE_PRODUCTS_FROM_CAMPAIGN: 'REMOVE_PRODUCTS_FROM_CAMPAIGN',
  SAVE_PAYMENTS_DATES: 'SAVE_PAYMENTS_DATES',
  DELETE_SAVED_PERIOD: 'DELETE_SAVED_PERIOD',
  EXPORT_SALES_PERIOD_TO_ECONOMIC: 'EXPORT_SALES_PERIOD_TO_ECONOMIC',
  GET_BANK_EXPORT_TRANSACTIONS: 'GET_BANK_EXPORT_TRANSACTIONS',
  EDIT_BANK_EXPORT_TRANSACTION: 'EDIT_BANK_EXPORT_TRANSACTION',
  DELETE_BANK_EXPORT_TRANSACTION: 'DELETE_BANK_EXPORT_TRANSACTION',
  GET_ECONOMIC_LAYOUTS: 'GET_ECONOMIC_LAYOUTS',
  GET_MARKET_MESSAGES: 'GET_MARKET_MESSAGES',
  DELETE_MARKET_MESSAGE: 'DELETE_MARKET_MESSAGE',
  DELETE_MARKET_MESSAGES: 'DELETE_MARKET_MESSAGES',
  GET_BOOKINGS_REPORT: 'GET_BOOKINGS_REPORT',
  UPDATE_MARKET_MESSAGES: 'UPDATE_MARKET_MESSAGES',
  UPDATE_MARKET_MESSAGE: 'UPDATE_MARKET_MESSAGE',
  ADD_USER_PRODUCT_TO_CAMPAIGN: 'ADD_USER_PRODUCT_TO_CAMPAIGN',
  REMOVE_USER_PRODUCT_FROM_CAMPAIGN: 'REMOVE_USER_PRODUCT_FROM_CAMPAIGN',
  GET_CUSTOMER_CAMPAIGN_REPORT: 'GET_CUSTOMER_CAMPAIGN_REPORT',

  // User
  GET_USER_MARKET: 'GET_USER_MARKET',
  GET_SALE_LINES_LIST: 'GET_SALE_LINES_LIST',
  GET_SALE_LINES_SUMMARY: 'GET_SALE_LINES_SUMMARY',
  SEND_FEEDBACK_TO_MARKET: 'SEND_FEEDBACK_TO_MARKET',
  GET_PRODUCT_SALE_LINE_LIST: 'GET_PRODUCT_SALE_LINE_LIST',
  GET_USER_PRODUCT_SHEET_LIST: 'GET_USER_PRODUCT_SHEET_LIST',
  GET_USER_PRODUCT_SHEET: 'GET_USER_PRODUCT_SHEET',
  CREATE_USER_PRODUCT_SHEET: 'CREATE_USER_PRODUCT_SHEET',
  UPDATE_USER_PRODUCT_SHEET: 'UPDATE_USER_PRODUCT_SHEET',
  COMPLETE_USER_PRODUCT_SHEET: 'COMPLETE_USER_PRODUCT_SHEET',
  PRINT_USER_PRODUCT_SHEET: 'PRINT_USER_PRODUCT_SHEET',
  DELETE_USER_PRODUCT_SHEET: 'DELETE_USER_PRODUCT_SHEET',
  GET_USER_PRODUCT_LIST: 'GET_USER_PRODUCT_LIST',
  CREATE_USER_PRODUCTS: 'CREATE_USER_PRODUCTS',
  REQUEST_PRODUCT_CAMPAIGN: 'REQUEST_PRODUCT_CAMPAIGN',
  REQUEST_PRODUCT_CAMPAIGN_REMOVAL: 'REQUEST_PRODUCT_CAMPAIGN_REMOVAL',
}

export const mutationTypes = {
  // Base
  SET_LOADER: 'SET_LOADER',
  SET_MARKET_HEADER: 'SET_MARKET_HEADER',
  SET_TOKEN: 'SET_TOKEN',
  SET_AUTH: 'SET_AUTH',
  SET_USER: 'SET_USER',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
  LOGOUT_CUSTOMER: 'LOGOUT_CUSTOMER',
  LOGOUT_ADMIN: 'LOGOUT_ADMIN',
  SET_MARKET_INSTAGRAM_MEDIA: 'SET_MARKET_INSTAGRAM_MEDIA',

  // Anonymous
  SET_RESET_PASSWORD_DATA: 'SET_RESET_PASSWORD_DATA',
  SET_CONFIRMATION_DATA: 'SET_CONFIRMATION_DATA',
  SET_ANON_MARKET: 'SET_ANON_MARKET',

  // Admin
  SET_ADMIN_MARKET: 'SET_ADMIN_MARKET',
  SET_MARKET_SETTINGS: 'SET_MARKET_SETTINGS',
  DELETE_MARKET_LOGO: 'DELETE_MARKET_LOGO',
  APPEND_MARKET_IMAGE: 'APPEND_MARKET_IMAGE',
  DELETE_MARKET_IMAGE: 'DELETE_MARKET_IMAGE',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  SET_ALL_CUSTOMERS: 'SET_ALL_CUSTOMERS',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_CUSTOMER_PAGE: 'SET_CUSTOMER_PAGE',
  SET_CUSTOMER_FILTERS: 'SET_CUSTOMER_FILTERS',
  SET_CUSTOMER_PAGES_COUNT: 'SET_CUSTOMER_PAGES_COUNT',
  APPEND_CUSTOMER: 'APPEND_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  VERIFY_CUSTOMER: 'VERIFY_CUSTOMER',
  SET_PAYMENTS_INFO: 'SET_PAYMENTS_INFO',
  SET_SAVED_PERIODS: 'SET_SAVED_PERIODS',
  DELETE_SAVED_PERIOD: 'DELETE_SAVED_PERIOD',
  SET_ECONOMIC_EXPORT_REQUESTED: 'SET_ECONOMIC_EXPORT_REQUESTED',
  SET_SHELF_LIST: 'SET_SHELF_LIST',
  SET_SHELF_FILTERS: 'SET_SHELF_FILTERS',
  SET_SHELF_PAGE: 'SET_SHELF_PAGE',
  SET_SHELF_PAGES_COUNT: 'SET_SHELF_PAGES_COUNT',
  APPEND_SHELF: 'APPEND_SHELF',
  DELETE_SHELF: 'DELETE_SHELF',
  UPDATE_SHELF: 'UPDATE_SHELF',
  SET_CAMPAIGN_LIST: 'SET_CAMPAIGN_LIST',
  SET_CAMPAIGN_FILTERS: 'SET_CAMPAIGN_FILTERS',
  SET_CAMPAIGN_PAGE: 'SET_CAMPAIGN_PAGE',
  SET_CAMPAIGN_PAGES_COUNT: 'SET_CAMPAIGN_PAGES_COUNT',
  APPEND_CAMPAIGN: 'APPEND_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  SET_SHELF_BOOKING_LIST: 'SET_SHELF_BOOKING_LIST',
  SET_SHELF_BOOKING_FILTERS: 'SET_SHELF_BOOKING_FILTERS',
  APPEND_SHELF_BOOKING: 'APPEND_SHELF_BOOKING',
  DELETE_SHELF_BOOKING: 'DELETE_SHELF_BOOKING',
  UPDATE_SHELF_BOOKING: 'UPDATE_SHELF_BOOKING',
  SET_CUSTOMER_BOOKINGS: 'SET_CUSTOMER_BOOKINGS',
  SET_CUSTOMER_BOOKING_PAGE: 'SET_CUSTOMER_BOOKING_PAGE',
  SET_CUSTOMER_BOOKING_PAGES_COUNT: 'SET_CUSTOMER_BOOKING_PAGES_COUNT',
  SET_CUSTOMER_BOOKING_FILTERS: 'SET_CUSTOMER_BOOKING_FILTERS',
  SET_CUSTOMER_PRODUCT_SHEETS: 'SET_CUSTOMER_PRODUCT_SHEETS',
  SET_CUSTOMER_PRODUCT_SHEET: 'SET_CUSTOMER_PRODUCT_SHEET',
  SET_CUSTOMER_PRODUCT_SHEETS_PAGE: 'SET_CUSTOMER_PRODUCT_SHEETS_PAGE',
  SET_CUSTOMER_PRODUCT_SHEETS_PAGES_COUNT: 'SET_CUSTOMER_PRODUCT_SHEETS_PAGES_COUNT',
  SET_CUSTOMER_PRODUCT_SHEETS_FILTERS: 'SET_CUSTOMER_PRODUCT_SHEETS_FILTERS',
  UPDATE_CUSTOMER_PRODUCT_SHEET: 'UPDATE_CUSTOMER_PRODUCT_SHEET',
  APPEND_CUSTOMER_PRODUCT_SHEET: 'APPEND_CUSTOMER_PRODUCT_SHEET',
  DELETE_CUSTOMER_PRODUCT_SHEET: 'DELETE_CUSTOMER_PRODUCT_SHEET',
  SET_CUSTOMER_PRODUCT_SALE_LINE_LIST: 'SET_CUSTOMER_PRODUCT_SALE_LINE_LIST',
  SET_CUSTOMER_PRODUCT_LIST: 'SET_CUSTOMER_PRODUCT_LIST',
  SET_CUSTOMER_PRODUCT_FILTERS: 'SET_CUSTOMER_PRODUCT_FILTERS',
  SET_CUSTOMER_PRODUCT_PAGE: 'SET_CUSTOMER_PRODUCT_PAGE',
  SET_CUSTOMER_PRODUCT_PAGES_COUNT: 'SET_CUSTOMER_PRODUCT_PAGES_COUNT',
  SET_SAVE_TYPE: 'SET_SAVE_TYPE',
  SET_BANK_EXPORT_TRANSACTIONS: 'SET_BANK_EXPORT_TRANSACTIONS',
  SET_BANK_EXPORT_TRANSACTIONS_FILTERS: 'SET_BANK_EXPORT_TRANSACTIONS_FILTERS',
  UPDATE_BANK_EXPORT_TRANSACTION: 'UPDATE_BANK_EXPORT_TRANSACTION',
  DELETE_BANK_EXPORT_TRANSACTION: 'DELETE_BANK_EXPORT_TRANSACTION',
  SET_ECONOMIC_LAYOUTS: 'SET_ECONOMIC_LAYOUTS',
  SET_MARKET_MESSAGES: 'SET_MARKET_MESSAGES',
  SET_MARKET_MESSAGES_PAGE: 'SET_MARKET_MESSAGES_PAGE',
  SET_MARKET_MESSAGES_PAGES_COUNT: 'SET_MARKET_MESSAGES_PAGES_COUNT',
  SET_MARKET_MESSAGES_FILTERS: 'SET_MARKET_MESSAGES_FILTERS',
  DELETE_MARKET_MESSAGE: 'DELETE_MARKET_MESSAGE',
  DELETE_MARKET_MESSAGES: 'DELETE_MARKET_MESSAGES',
  SET_ADMIN_NAV_ITEMS: 'SET_ADMIN_NAV_ITEMS',
  SET_UNSEEN_MESSAGES_NUMBER: 'SET_UNSEEN_MESSAGES_NUMBER',
  UPDATE_MARKET_MESSAGE: 'UPDATE_MARKET_MESSAGE',
  SET_BOOKINGS_REPORT: 'SET_BOOKINGS_REPORT',
  SET_BOOKINGS_REPORT_FILTERS: 'SET_BOOKINGS_REPORT_FILTERS',
  SET_CUSTOMER_CAMPAIGN_REPORT: 'SET_CUSTOMER_CAMPAIGN_REPORT',
  SET_CUSTOMER_CAMPAIGN_REPORT_FILTERS: 'SET_CUSTOMER_CAMPAIGN_REPORT_FILTERS',
  SET_CUSTOMER_CAMPAIGN_REPORT_PAGE: 'SET_CUSTOMER_CAMPAIGN_REPORT_PAGE',
  SET_CUSTOMER_CAMPAIGN_REPORT_PAGES_COUNT: 'SET_CUSTOMER_CAMPAIGN_REPORT_PAGES_COUNT',
  UPDATE_MARKET_MESSAGES: 'UPDATE_MARKET_MESSAGES',

  // User
  SET_USER_NAV_ITEMS: 'SET_USER_NAV_ITEMS',
  SET_USER_MARKET: 'SET_USER_MARKET',
  SET_SALE_LINES_LIST: 'SET_SALE_LINES_LIST',
  SET_SALE_LINES_SUMMARY: 'SET_SALE_LINES_SUMMARY',
  SET_PRODUCT_SALE_LINE_LIST: 'SET_PRODUCT_SALE_LINE_LIST',
  SET_PRODUCT_SHEET_LIST: 'SET_PRODUCT_SHEET_LIST',
  SET_PRODUCT_SHEET: 'SET_PRODUCT_SHEET',
  SET_PRODUCT_SHEET_FILTERS: 'SET_PRODUCT_SHEET_FILTERS',
  SET_PRODUCT_SHEET_PAGE: 'SET_PRODUCT_SHEET_PAGE',
  SET_PRODUCT_SHEET_PAGES_COUNT: 'SET_PRODUCT_SHEET_PAGES_COUNT',
  APPEND_PRODUCT_SHEET: 'APPEND_PRODUCT_SHEET',
  DELETE_PRODUCT_SHEET: 'DELETE_PRODUCT_SHEET',
  UPDATE_PRODUCT_SHEET: 'UPDATE_PRODUCT_SHEET',
  UPDATE_SELECTED_PRODUCT_SHEET: 'UPDATE_SELECTED_PRODUCT_SHEET',
  SET_PRODUCT_LIST: 'SET_PRODUCT_LIST',
  SET_PRODUCT_FILTERS: 'SET_PRODUCT_FILTERS',
  SET_PRODUCT_PAGE: 'SET_PRODUCT_PAGE',
  SET_PRODUCT_PAGES_COUNT: 'SET_PRODUCT_PAGES_COUNT',
  SET_CAMPAIGN_REQUEST: 'SET_CAMPAIGN_REQUEST',

  // User and admin
  SET_SALE_LINES_FILTERS: 'SET_SALE_LINES_FILTERS',
}
