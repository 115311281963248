<template>
  <div class="footer">
    <footer class="d-flex align-items-center justify-content-center p-3 bg-light" style="flex-direction: column">
      <a
        v-if="!isGlobalApp"
        :href="noviposUrl"
        target="_blank"
        class="mb-2"
      >
        <b-img class="novipos-logo" :src="require('@base/assets/images/novipos-logo.png')"  />
      </a>
      <span class="pre-wrap" v-html="$t('pages.home.footer.text', {
        currentYear: new Date().getFullYear(),
        appName,
      })">
      </span>
    </footer>
  </div>
</template>

<script>

import { NOVIPOS_URL, APP_NAME, IS_GLOBAL_APP } from '@base/configs'

  export default {
    name: 'CustomFooter',

    data () {
      return  {
        noviposUrl: NOVIPOS_URL,
        appName: APP_NAME,
        isGlobalApp: IS_GLOBAL_APP,
      }
    }
  }
</script>

<style scoped>

</style>
